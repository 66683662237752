import dayjs from "dayjs";
import { stringIncludes } from "./utils";

function getYearNow() {
 return dayjs().year();
}
function getMonthNow(date) {
 if (!date) return dayjs().month() + 1;
 return dayjs(date)?.month() + 1;
}

function getDateRange(
 startDate,
 endDate,
 customFormat = null,
 longDate = true
) {
 if (!startDate || !endDate) return "";
 let dateFormat = longDate ? "D MMMM YYYY" : "D-MMM-YYYY";
 if (customFormat) dateFormat = customFormat;

 const resultStartDate = dayjs(startDate).startOf("day").format(dateFormat);
 const resultEndDate = dayjs(endDate).endOf("day").format(dateFormat);
 if (!resultStartDate || !resultEndDate) return "";
 return `${resultStartDate} - ${resultEndDate}`;
}

function isValidDate(date) {
 if (!date) return false;

 // Jika `date` sudah berupa objek dayjs, langsung cek validitasnya
 if (dayjs.isDayjs(date)) {
  return date.isValid();
 }

 // Jika `date` bukan objek dayjs, coba parsing dan cek validitasnya
 const parsedDate = dayjs(date);
 return parsedDate.isValid();
}

function getDateNow(format = "DD-MM-YYYY") {
 if (stringIncludes(format, "date")) {
  return dayjs().toDate();
 }
 if (stringIncludes(format, "iso")) {
  return dayjs().toISOString();
 }
 if (stringIncludes(format, "dayjs")) {
  return dayjs();
 }
 return dayjs().format(format);
}

function getDate(date, format = "DD-MM-YYYY") {
 if (!isValidDate(date)) return null;

 if (stringIncludes(format, "date")) {
  return dayjs(date).toDate();
 }
 if (stringIncludes(format, "iso")) {
  return dayjs(date).toISOString();
 }
 if (stringIncludes(format, "dayjs")) {
  return dayjs(date);
 }
 return dayjs(date).format(format);
}

//const result = setDate(dayjs(), 26, -1, 'month'); <- example usage
const setDate = (
 targetDate,
 format = "DD-MM-YYYY",
 date,
 setProperty = "date",
 daysToAddOrSubtract = 0,
 unit = "day"
) => {
 if (!isValidDate(targetDate)) return false;
 const newDate = dayjs(targetDate);
 const d = newDate.get(setProperty);
 if (stringIncludes(format, "date")) {
  return dayjs(targetDate)
   .set(setProperty, date ?? d)
   .add(daysToAddOrSubtract, unit)
   .toDate();
 }
 if (stringIncludes(format, "iso")) {
  return dayjs(targetDate)
   .set(setProperty, date ?? d)
   .add(daysToAddOrSubtract, unit)
   .toISOString();
 }
 if (stringIncludes(format, "dayjs")) {
  return dayjs(targetDate)
   .set(setProperty, date ?? d)
   .add(daysToAddOrSubtract, unit);
 }
 return dayjs(targetDate)
  .set(setProperty, date ?? d)
  .add(daysToAddOrSubtract, unit)
  .format(format);
};

export {
 getYearNow,
 getMonthNow,
 getDateRange,
 isValidDate,
 getDateNow,
 setDate,
 getDate,
};

/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader.jsx";
import Message from "../../components/Message.jsx";
import { Route, useLocation } from "react-router-dom";
import SearchBox from "../../components/SearchBox.jsx";
import {
 Row,
 Col,
 Container,
 Form,
 ToggleButtonGroup,
 ToggleButton,
} from "react-bootstrap";
import { capitalizeEachWord, stringIncludes } from "../../utils/utils.jsx";
import dayjs from "dayjs";
import { getAnalysisAttendanceSummaryByDate } from "../../actions/attendanceSummaryActions.jsx";
import AttendanceTableView from "./AttendanceTableView.jsx";
import AttendanceExportToExcel from "../../components/AttendanceExportToExcel.jsx";
import { DatePicker } from "rsuite";
import { logout } from "../../actions/userActions.jsx";
import FormSelect from "../../components/select/FormSelect.jsx";
import { getAllOutlet } from "../../actions/outletActions.jsx";
import { useQuery } from "@tanstack/react-query";
import { getLatestAttendance } from "../../actions/attendanceActions.jsx";
import EmployeeCountByStatus from "../../components/employee/EmployeeCountByStatus.jsx";
import LatestAttendanceUpdated from "../../components/employee/LatestAttendanceUpdated.jsx";
import {
 getLocalStorageSettings,
 updateLocalStorageSettings,
} from "../../utils/localStorage.jsx";
import { getDate } from "../../utils/time.jsx";

const AddAttendanceView = ({ match, history }) => {
 const existingOutletSettings = getLocalStorageSettings("attendance", "outlet");

 const location = useLocation(); // Added for reading the URL
 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, userInfo } = userLogin;
 const token = userInfo?.token;

 const [isActiveEmployee, setIsActiveEmployee] = useState(true);

 const [outlet, setOutlet] = useState(
  userInfo?.isAdmin || userInfo?.isCompanyLeader
   ? existingOutletSettings
   : userInfo?.outlet
 );
 //console.log(`userInfo: ${JSON.stringify(userInfo)}`);
 //console.log(`userInfo?.outlet: ${userInfo?.outlet}`);

 const lastMonth = dayjs().subtract(2, "month").date(26).toDate();
 const currentMonth = dayjs().subtract(1, "month").date(26).toDate();
 const [month, setMonth] = useState(
  dayjs().get("date") > 15 ? currentMonth : lastMonth
 );

 //console.log(
 // `month: ${month}\ncurrentMonth:${currentMonth}\nlastMonth:${lastMonth}`
 //);

 const [date, setDate] = useState(month);

 const attendanceDataEnter = useSelector((state) => state.attendanceDataEnter);
 const { loading: addAttendanceLoading } = attendanceDataEnter;

 const keyword = match.params.keyword;

 const dispatch = useDispatch();

 const {
  data: dataLatestAttendance,
  error: errorLatestAttendance,
  isLoading: isLoadingLatestAttendance,
  refetch: refetchLatestAttendance,
 } = useQuery({
  queryKey: ["getLatestAttendance", {}], // Query key as an array
  queryFn: getLatestAttendance, // Function to fetch data
  //enabled: token ? true : false, // Only fetch if token is available
  enabled: false, // Disable automatic fetching
  retry: false, // Disable retries on failure
  refetchOnError: false, // Disable automatic refetch when there's an error
 });
 const latestAttendance = dataLatestAttendance || [];

 const {
  data: attendanceSummary,
  error: errorAttendanceSummary,
  isLoading: isLoadingAttendanceSummary,
  refetch: refetchAttendanceSummary,
 } = useQuery({
  queryKey: [
   "getAnalysisAttendanceSummaryByDate",
   {
    isActiveEmployee: isActiveEmployee,
    keyword: keyword,
    outlet: outlet,
    date: getDate(date, "YYYY-MM-DD"),
   },
  ], // Query key as an array
  queryFn: getAnalysisAttendanceSummaryByDate, // Function to fetch data
  //enabled: token ? true : false, // Only fetch if token is available
  enabled: false, // Disable automatic fetching
  retry: false, // Disable retries on failure
  refetchOnError: false, // Disable automatic refetch when there's an error
 });
 //console.log(`data:${JSON.stringify(attendanceSummary, null, 2)}`);

 const error =
  errorLatestAttendance?.response?.data?.message ||
  errorAttendanceSummary?.response?.data?.message;
 console.log(`error:${JSON.stringify(error, null, 2)}`);

 const outletList = useSelector((state) => state.outletList);
 const { loading: loadingOutlets, error: errorOutlets, outlets } = outletList;

 const handleIsActiveEmployee = (value) => {
  setIsActiveEmployee(value);

  // Update the URL with the selected outlet
  const searchParams = new URLSearchParams(location.search);
  searchParams?.set("isActiveEmployee", value);
  history.push({ search: searchParams?.toString() });
 };

 const handleOutletChange = (e) => {
  const selectedOutlet = e.target.value;
  setOutlet(selectedOutlet);
  if (!selectedOutlet) return;

  updateLocalStorageSettings("attendance", "outlet", outlet);
 };

 const handleDateChange = (date) => {
  if (!date) return;

  const newDate = dayjs(date);

  setDate(
   dayjs(`${newDate.format("YYYY-MM")}-26`)
    .startOf("day")
    .toDate()
  );

  const searchParams = new URLSearchParams(location.search);
  searchParams?.set("date", newDate.format("YYYY-MM-26"));
  history.push({ search: searchParams?.toString() });
 };

 // eslint-disable-next-line
 useEffect(() => {
  if ((!userLoading && !userInfo) || stringIncludes(error, "not authorized")) {
   dispatch(logout());
   history.push("/login");
  }

  if (!isLoadingAttendanceSummary) {
   refetchAttendanceSummary();
  }

  // Update outlet state from URL if present
  const searchParams = new URLSearchParams(location.search);

  const isActiveEmployeeParam = searchParams?.get("isActiveEmployee");
  if (isActiveEmployeeParam === "false") {
   setIsActiveEmployee(false); // Set the outlet from URL
  } else if (isActiveEmployeeParam === "true") {
   setIsActiveEmployee(true); // Set the outlet from URL
  }

  const dateParam = searchParams?.get("date");
  if (dateParam) {
   const newDate = dayjs(dateParam);
   if (!newDate.isSame(dayjs(date), "month")) {
    setDate(newDate.startOf("day").toDate());
   }
  }

  if (!loadingOutlets && !outlets?.length) {
   dispatch(getAllOutlet());
  }
  //console.log(outlets);

  if (outlet) {
   updateLocalStorageSettings("attendance", "outlet", outlet);
  }

  if (!isLoadingLatestAttendance && !error) {
   refetchLatestAttendance();
  }
 }, [
  userLoading,
  userInfo,
  outlet,
  date,
  latestAttendance,
  keyword,
  outlets,
  isActiveEmployee,
 ]);

 return (
  <Container>
   <Row>
    <Col>
     <h5>{`Attendance ${capitalizeEachWord(outlet)}`}</h5>
    </Col>
    <Col className="d-flex justify-content-end">
     <AttendanceExportToExcel
      attendanceData={attendanceSummary}
      startDate={date}
      outlet={outlet}
     />
    </Col>
   </Row>
   <Row>
    <Col md="auto">
     <h6>{`${dayjs(date).format("DD MMMM YYYY")} - ${dayjs(date)
      .add(1, "month")
      .subtract(1, "day")
      .format("DD MMMM YYYY")}`}</h6>
    </Col>
   </Row>
   <Row>
    <Col></Col>
   </Row>

   <Row>
    <Col md="auto">
     <ToggleButtonGroup type="radio" name="options">
      <ToggleButton
       key="isActiveEmployee"
       id="isActiveEmployeeRadio1"
       value={true}
       name="radio"
       onChange={(e) => handleIsActiveEmployee(e.target.value)}
       style={{
        backgroundColor: isActiveEmployee === true ? "#dc3545" : "#6c757d",
        border: "none", // Remove the outline
       }}
       disabled={isLoadingAttendanceSummary}
      >
       Active
      </ToggleButton>
      <ToggleButton
       key="resignEmployee"
       id="isActiveEmployeeRadio2"
       value={false}
       name="radio"
       onChange={(e) => handleIsActiveEmployee(e.target.value)}
       style={{
        backgroundColor: isActiveEmployee === false ? "#dc3545" : "#6c757d",
        border: "none", // Remove the outline
       }}
       disabled={isLoadingAttendanceSummary}
      >
       Resigned
      </ToggleButton>
     </ToggleButtonGroup>
    </Col>
    <Col md="auto" xs="auto">
     <Form>
      {userInfo?.isAdmin || userInfo?.isCompanyLeader ? (
       <FormSelect
        controlId="outlet"
        value={outlet || ""}
        onChange={handleOutletChange}
        options={outlets || ""}
        defaultLabel={false}
        disabled={isLoadingAttendanceSummary}
       />
      ) : (
       <></>
      )}
     </Form>
    </Col>
    <Col>
     <DatePicker
      format="MM-yyyy"
      placeholder={date ? dayjs(date).format("MM-YYYY") : ""}
      onChange={handleDateChange}
      ranges={[]}
     />
    </Col>

    <EmployeeCountByStatus employee={attendanceSummary} />
    <Col md="auto">
     <Route
      render={({ history }) => (
       <SearchBox
        history={history}
        pageName={"attendance"}
        keyword={keyword}
        outlet={outlet}
        isActiveEmployee={isActiveEmployee}
        date={date}
        userInfo={userInfo}
       />
      )}
     />
    </Col>
   </Row>

   <Row style={{ fontSize: "80%" }}>
    {latestAttendance && (
     <LatestAttendanceUpdated attendance={latestAttendance} />
    )}
   </Row>

   {isLoadingAttendanceSummary || addAttendanceLoading || loadingOutlets ? (
    <Loader />
   ) : error || errorOutlets ? (
    <Message variant="danger">{error}</Message>
   ) : (
    <>
     <AttendanceTableView
      data={attendanceSummary || []}
      isActiveEmployee={isActiveEmployee}
      outlet={outlet || ""}
      date={date || ""}
      userInfo={userInfo || ""}
      refetchLatestAttendance={refetchLatestAttendance}
      refetchAttendanceSummary={refetchAttendanceSummary}
     />
    </>
   )}
  </Container>
 );
};

export default AddAttendanceView;

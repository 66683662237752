import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const FormContainer = ({ children, maxWidth = 500 }) => {
 return (
  <Container className="container-fluid" style={{ marginLeft: "5%" }}>
   <Row
    className="justify-content-sm-center"
    style={{
     backgroundColor: "rgba(225, 225, 225, 0.87)",
     borderRadius: 8,
     color: "#000000",
     backdropFilter: "blur(2px)" /* Efek blur */,
     maxWidth: maxWidth,
    }}
   >
    <Col>{children}</Col>
   </Row>
  </Container>
 );
};

export default FormContainer;

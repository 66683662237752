import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { getUsers } from "../actions/userActions";
import { logout } from "../actions/userActions";
import { stringIncludes } from "../utils/utils";
import { useQuery } from "@tanstack/react-query";
import UserListTableView from "./UserListTableView";

const UserListView = ({ history }) => {
 const dispatch = useDispatch();
 const [filterText, setFilterText] = useState("");

 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, error: userError, userInfo } = userLogin;
 const token = userInfo?.token;

 const userDelete = useSelector((state) => state.userDelete);
 const { success: successDelete } = userDelete;

 const {
  data: dataGetUsers,
  isLoading: loadingGetUsers,
  error: errorGetUsers,
 } = useQuery({
  queryKey: ["getUsers", {}], // Query key as an array
  queryFn: getUsers, // Function to fetch data
  enabled: userInfo?.isAdmin && token ? true : false, // Only fetch if token is available
  staleTime: 1000 * 60, // Data considered fresh for 60 seconds (60000 ms)
  refetchInterval: 1000 * 3,
  retry: false, // Disable retries on failure
  refetchOnError: false, // Disable automatic refetch when there's an error
 });

 const users = useMemo(() => {
  return dataGetUsers || dataGetUsers?.message || [];
 }, [dataGetUsers]);

 const loading = useMemo(() => {
  return loadingGetUsers || userLoading;
 }, [loadingGetUsers, userLoading]);

 const error =
  errorGetUsers?.response?.data?.message ||
  errorGetUsers?.message ||
  userError?.response?.data?.message ||
  userError?.message ||
  "";

 useEffect(() => {
  if ((!userLoading && !userInfo) || stringIncludes(error, "not authorized")) {
   dispatch(logout());
   history.push("/login");
  }
  // eslint-disable-next-line
 }, [successDelete, userInfo, users]);

 return (
  <Container>
   {loading ? (
    <Loader />
   ) : error ? (
    <Message variant="danger">{error}</Message>
   ) : (
    <>
     {
      <Row className="justify-content-md-between">
       <Col>
        <h5>Users</h5>
       </Col>
       <Col md={4}>
        <Form.Control
         type="text"
         placeholder="Search by name, employee ID or email"
         value={filterText}
         onChange={(e) => setFilterText(e.target.value)}
        />
       </Col>
      </Row>
     }
     <UserListTableView
      data={users}
      filterText={filterText}
      setFilterText={setFilterText}
      error={error}
     ></UserListTableView>
    </>
   )}
  </Container>
 );
};

export default UserListView;

import axios from "axios";
import { getAxiosTargetPath } from "../utils/utils";
import store from "../store";
import dayjs from "dayjs";

export const updateEmployeeSummary = async ({ queryKey }) => {
 const [
  ,
  {
   date = dayjs().set("date", 26).subtract(1, "month").format("YYYY-MM-DD"),
   outlet = "ALL OUTLETS",
  },
 ] = queryKey;

 const {
  userLogin: { userInfo },
 } = store.getState();

 const config = {
  headers: {
   Authorization: `Bearer ${userInfo?.token}`,
  },
 };
 const axiosPath = getAxiosTargetPath(
  `/employeeSummary/update?outlet=${outlet}&date=${date}`
 );
 const { data } = await axios.get(axiosPath, config);
 return data;
};

export const getEmployeeSummary = async ({ queryKey }) => {
 const [
  ,
  {
   date = dayjs().set("date", 26).subtract(1, "month").format("YYYY-MM-DD"),
   outlet = "ALL OUTLETS",
  },
 ] = queryKey;

 const {
  userLogin: { userInfo },
 } = store.getState();

 const config = {
  headers: {
   Authorization: `Bearer ${userInfo?.token}`,
  },
 };
 const axiosPath = getAxiosTargetPath(
  `/employeeSummary/?outlet=${outlet}&date=${date}`
 );
 const { data } = await axios.get(axiosPath, config);
 return data;
};

export const getLatestEmployeeSummary = async () => {
 const {
  userLogin: { userInfo },
 } = store.getState();

 const config = {
  headers: {
   Authorization: `Bearer ${userInfo?.token}`,
  },
 };
 const axiosPath = getAxiosTargetPath(`/employeeSummary/latest`);
 const { data } = await axios.get(axiosPath, config);
 return data;
};

export const getAllEmployeeSummary = async ({ queryKey }) => {
 const [
  ,
  {
   date = dayjs().set("date", 26).subtract(1, "month").format("YYYY-MM-DD"),
   outlet = "ALL OUTLETS",
  },
 ] = queryKey;

 const {
  userLogin: { userInfo },
 } = store.getState();

 const config = {
  headers: {
   Authorization: `Bearer ${userInfo?.token}`,
  },
 };
 const axiosPath = getAxiosTargetPath(
  `/employeeSummary/all?outlet=${outlet}&date=${date}`
 );
 const { data } = await axios.get(axiosPath, config);
 return data;
};

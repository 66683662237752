import React from "react";
import { Table } from "react-bootstrap";
import {
 useReactTable,
 flexRender,
 getCoreRowModel,
 getSortedRowModel,
 getFilteredRowModel,
} from "@tanstack/react-table";

const ReusableTable = ({
 columns,
 data,
 error = false,
 filterText,
 setFilterText,
}) => {
 const table = useReactTable({
  data,
  columns,
  state: {
   globalFilter: filterText,
  },
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getSortedRowModel(),
  getFilteredRowModel: getFilteredRowModel(),
  onGlobalFilterChange: setFilterText,
  globalFilterFn: "includesString",
 });

 if (!columns || !data) return null;

 return (
  <>
   {error ? (
    <div className="error">{error}</div>
   ) : (
    <Table striped bordered hover size="sm" className="custom-table">
     <thead className="sticky-header">
      {table.getHeaderGroups().map((headerGroup) => (
       <tr key={headerGroup.id}>
        {headerGroup.headers.map((column) => {
         const alignment = column.column.columnDef.headerAlign || "center"; // default to center
         return (
          <th
           key={column.id}
           onClick={column.column.getToggleSortingHandler()}
           style={{ textAlign: alignment }} // Apply alignment
          >
           {flexRender(column.column.columnDef.header, column.getContext())}
           {column.column.getIsSorted() &&
            (column.column.getIsSorted() === "desc" ? " 🔽" : " 🔼")}
          </th>
         );
        })}
       </tr>
      ))}
     </thead>
     <tbody>
      {table.getRowModel().rows.map((row) => (
       <tr key={row.id}>
        {row.getVisibleCells().map((cell) => {
         const alignment = cell.column.columnDef.headerAlign || "center"; // default to center
         return (
          <td key={cell.id} style={{ textAlign: alignment }}>
           {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
         );
        })}
       </tr>
      ))}
     </tbody>
    </Table>
   )}
  </>
 );
};

export default ReusableTable;

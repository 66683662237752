export const getArrayValue = (data, key) => {
 if (!Array.isArray(data) || !data?.length || !key) return [];

 return (
  data.map((record) => {
   const keys = key.split("."); // Memecah key yang bertingkat
   return keys.reduce((acc, k) => (acc ? acc[k] : undefined), record); // Mengakses nilai bertingkat
  }) ?? []
 );
};

/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { stringIncludes } from "../../utils/utils.jsx";
import { logout } from "../../actions/userActions.jsx";
import LoadingErrorHandler from "../../components/LoadingErrorHandler.jsx";
import Greeting from "./Greeting.jsx";
import AnaliticsOverview from "./AnalyticsOverview.jsx";
import { getAllEmployeeSummary } from "../../actions/employeeSummaryActions.jsx";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { getAllOutlet } from "../../actions/outletActions.jsx";
import FormSelect from "../../components/select/FormSelect.jsx";
import {
 getLocalStorageSettings,
 updateLocalStorageSettings,
} from "../../utils/localStorage.jsx";
import { DatePicker } from "rsuite";
import { getDate } from "../../utils/time.jsx";

const DashboardView = ({ match, history }) => {
 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, userInfo } = userLogin;
 const token = userInfo?.token;
 const existingOutletSettings = getLocalStorageSettings("dashboard", "outlet");

 const [outlet, setOutlet] = useState(
  userInfo?.isAdmin || userInfo?.isCompanyLeader
   ? existingOutletSettings ?? userInfo?.outlet ?? "ALL OUTLETS"
   : userInfo?.outlet
 );

 const outletList = useSelector((state) => state.outletList);
 const {
  loading: outletListLoading,
  error: errorOutlets,
  outlets,
 } = outletList;

 const handleOutletChange = (e) => {
  const selectedOutlet = e.target.value;
  setOutlet(selectedOutlet);
  updateLocalStorageSettings("dashboard", "outlet", selectedOutlet);
 };

 const lastMonth = dayjs().subtract(1, "month").date(26).toDate();

 const [date, setDate] = useState(lastMonth);
 console.log(`[dashboard] date: ${getDate(date, "YYYY-MM-DD")}`);

 //const employeeSummary = data?.employeeSummary || [];

 const {
  data: dataGetAllEmployeeSummary,
  isLoading: isLoadingGetAllEmployeeSummary,
  error: errorGetAllEmployeeSummary,
  refetch: fetchGetAllEmployeeSummary,
 } = useQuery({
  queryKey: [
   "getAllEmployeeSummary",
   { date: date ? dayjs(date)?.format("YYYY-MM-DD") : null, outlet },
  ], // Query key as an array
  queryFn: getAllEmployeeSummary, // Function to fetch data
  enabled: token ? true : false, // Only fetch if token is available
  staleTime: 1000 * 60, // Data considered fresh for 60 seconds (60000 ms)
  refetchInterval: 1000 * 20,
  retry: false, // Disable retries on failure
  refetchOnError: false, // Disable automatic refetch when there's an error
 });
 const employeeSummaries = dataGetAllEmployeeSummary?.employeeSummaries || [];
 const error =
  errorGetAllEmployeeSummary?.response?.data?.message ||
  errorGetAllEmployeeSummary?.message ||
  errorOutlets ||
  "";

 let latestSummary = [];
 let employeeSummary = [];
 if (employeeSummaries?.length > 0) {
  latestSummary = employeeSummaries?.reduce((latest, current) => {
   // Membandingkan tanggal dalam format yang sama, pastikan `date` merupakan string yang valid
   return new Date(current.date) > new Date(latest.date) ? current : latest;
  }, employeeSummaries[0]); // Mulai dengan objek pertama dalam array
  employeeSummary = latestSummary || [];
 }

 const handleDateChange = (date) => {
  if (!date) return;

  const newDate = dayjs(date);

  setDate(
   dayjs(`${newDate.format("YYYY-MM")}-26`)
    .subtract(1, "month")
    .startOf("day")
    .toDate()
  );
 };

 const dispatch = useDispatch();
 useEffect(() => {
  if ((!userLoading && !userInfo) || stringIncludes(error, "not authorized")) {
   dispatch(logout());
   history.push("/login");
  }

  if (!outletListLoading && !outlets?.length && !error) {
   dispatch(getAllOutlet());
  }
  if (!isLoadingGetAllEmployeeSummary && !error) {
   fetchGetAllEmployeeSummary();
  }
  // eslint-disable-next-line
 }, [
  userLoading,
  userInfo,
  history,
  employeeSummary,
  employeeSummaries,
  outlet,
  error,
  date,
 ]);

 return (
  <>
   <Container fluid>
    <Greeting userInfo={userInfo} />
   </Container>
   <Container>
    <Row className="xs">
     {userInfo?.isAdmin || userInfo?.isCompanyLeader ? (
      <FormSelect
       controlId="outlet"
       value={outlet || ""}
       onChange={handleOutletChange}
       options={outlets || ""}
       defaultLabel={false}
       customLabel="ALL OUTLETS"
      />
     ) : (
      <></>
     )}
     <Col>
      <DatePicker
       format="M-yyyy"
       placeholder={
        date
         ? getDate(dayjs(date).add(1, "month").toDate(), "M-YYYY")
         : "Select date"
       }
       onChange={handleDateChange}
       ranges={[]}
       //defaultValue={date ? dayjs(date).startOf("day").toDate() : null} // Ensure this is a dayjs object
      />
     </Col>
    </Row>
   </Container>
   <LoadingErrorHandler loading={isLoadingGetAllEmployeeSummary} error={error}>
    <AnaliticsOverview
     data={employeeSummary}
     allData={employeeSummaries}
     date={date}
    />
   </LoadingErrorHandler>
  </>
 );
};

export default DashboardView;

import * as XLSX from "xlsx";
import dayjs from "dayjs";
import {
 capitalizeEachWord,
 getWorkDuration,
 stringIncludes,
 toLowerCaseSafe,
} from "../utils/utils";

const downloadEmployeeToExcel = (
 employees = [],
 outlets = [],
 outlet = "",
 status = "tetap",
 region = "sumatera utara",
 groupSriwijaya = false,
 religion = ""
) => {
 // Main function to handle Excel download
 const downloadExcel = () => {
  if (!employees?.length || !outlets?.length) {
   alert("Tidak ada data untuk diekspor!");
   return;
  }

  const workbook = XLSX.utils.book_new();
  // Generate outlet sheets dynamically based on available outlets
  const generateOutletSheets = (outlets) => {
   const outletMap = {};
   if (!stringIncludes(outlet, "all outlets")) {
    return { [outlet]: [outlet] };
   }

   outlets?.forEach((item) => {
    if (!item || !item?.name || !stringIncludes(item?.region, region)) return;
    // Group outlets by their categories (assuming some structure in outlet data)
    if (!outletMap[item?.region]) {
     outletMap[item?.region] = [];
    }
    outletMap[item?.region].push(item?.name);
   });

   const result = outletMap?.[region]?.reduce((acc, outlet) => {
    acc[outlet] = [outlet];
    return acc;
   }, {});

   //console.log(`outletMap:${JSON.stringify(result, null, 2)}`);
   return result;
  };

  // Define outlet sheets based on region
  const outletSheets =
   stringIncludes(region, "sumatera utara") &&
   stringIncludes(outlet, "all outlets")
    ? groupSriwijaya
      ? {
         SRIWIJAYA: ["SRIWIJAYA", "KHALID MEDAN", "MANAGEMENT"],
         SUDIRMAN: ["SUDIRMAN"],
         SETIABUDI: ["SETIABUDI"],
        }
      : generateOutletSheets(outlets)
    : generateOutletSheets(outlets); // for other regions

  // Loop through each outlet region
  Object.keys(outletSheets).forEach((sheetName) => {
   if (!sheetName) {
    //console.log(`sheetName:${sheetName} is empty!`);
    return;
   }
   //console.log(`outletSheets:${JSON.stringify(outletSheets)}`);
   //console.log(`sheetName:${sheetName}`);
   const outletNames = outletSheets[sheetName]; // Renaming to outletNames to clarify it's an array
   //console.log(`outletNames:${outletNames}`);

   const filteredEmployees = employees?.filter((employee) => {
    if (!employee) return false;
    // Check if any outlet name from the outletNames array is included in the employee's outlet
    if (!outletNames.some((outlet) => stringIncludes(employee?.outlet, outlet)))
     return false;
    if (!stringIncludes(status, "all status")) {
     if (!stringIncludes(employee?.status, status)) return false;
    }
    if (!stringIncludes(religion, "all religions")) {
     if (!stringIncludes(employee?.religion, religion)) return false;
    }

    //console.log(
    // `Employee outlet: ${employee?.outlet}, Expected outlet: ${outletName}`
    //);

    if (!stringIncludes(employee?.region, region)) return false;

    //console.log(`employee?.name:${employee?.name} - ${employee?.outlet}`);

    // Check if the employee has already resigned
    if (employee?.dateResign && dayjs(employee?.dateResign).isBefore(dayjs()))
     return false;

    //console.log(
    // `Filtering by region: ${employee?.region}, expected region: ${region}`
    //);

    return true;
   });

   if (!filteredEmployees?.length) {
    console.log(
     `Tidak ada data untuk diekspor di sheet ${sheetName}! Filtered employees count: ${filteredEmployees.length} filteredEmployees:${filteredEmployees}`
    );
    return;
   }

   const headerRow = [
    "Employee ID",
    "Name",
    "Outlet",
    "Position",
    "Status",
    "Date In",
    "Date Resign",
    "Work Duration",
    "Bank Name",
    "Bank Account",
    "Email",
    "Phone",
    "Religion",
    "NIK KTP",
    "No. BPJS Kesehatan",
    "BPJS Kesehatan Description",
    "No. BPJS Ketenagakerjaan",
    "BPJS Ketenagakerjaan Description",
   ];

   const data = filteredEmployees?.map((employee) => [
    employee?.employeeID,
    employee?.name,
    employee?.outlet,
    employee?.position,
    capitalizeEachWord(employee?.status || "-"),
    employee?.dateIn ? dayjs(employee.dateIn).format("DD-MMM-YYYY") : "-",
    employee?.dateResign
     ? dayjs(employee.dateResign).format("DD-MMM-YYYY")
     : "-",
    getWorkDuration(employee?.dateIn, employee?.dateResign),
    employee?.bank,
    employee?.bankAccount,
    employee?.email,
    employee?.phone,
    employee?.religion,
    employee?.ktpID,
    employee?.bpjsKesehatan,
    employee?.bpjsKesehatanDescription,
    employee?.bpjsKetenagakerjaan,
    employee?.bpjsKetenagakerjaanDescription,
   ]);

   // Sort by name
   data.sort((a, b) => {
    //const [outletA, outletB] = [a[1]?.trim(), b[1]?.trim()];
    //if (outletA !== outletB) return outletA?.localeCompare(outletB);
    return a[1]?.trim()?.localeCompare(b[1]?.trim());
   });

   data.unshift(headerRow);
   const worksheet = XLSX.utils.aoa_to_sheet(data);

   // Append the filtered data for this outlet to the workbook
   XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  });

  // Generate Excel file
  const currentDate = dayjs().format("DDMMYYYY_HHmmss");
  //console.log(`status${status}`);
  let fileOutletName = "";
  if (!stringIncludes(outlet, "all outlets")) {
   fileOutletName = toLowerCaseSafe(outlet);
  } else {
   fileOutletName = toLowerCaseSafe(region);
  }

  XLSX.writeFile(
   workbook,
   `employees_${fileOutletName}_${toLowerCaseSafe(status)}_${toLowerCaseSafe(
    religion
   )}_${currentDate}.xlsx`
  );
 };

 downloadExcel();
};

export default downloadEmployeeToExcel;

import React, { useMemo } from "react";
import { getColumn, getNumberColumn, getTimeAgo } from "../utils/utils";
import ReusableTable from "../components/ReusableTable";
import { isValidDate } from "../utils/time";
import dayjs from "dayjs";
import { LinkContainer } from "react-router-bootstrap";
import { Button, Col, Row, Badge } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { deleteUser } from "../actions/userActions";
import { TooltipIcon } from "../components/Tooltip";

const UserListTableView = ({ data, error, filterText, setFilterText }) => {
 const dispatch = useDispatch();
 const deleteHandler = (item) => {
  if (window.confirm(`Delete this user ${item?.name}?`)) {
   dispatch(deleteUser(item?._id));
  }
 };

 const getRole = (item) => {
  if (!item) return "";
  if (item.isAdmin) return { label: "admin", color: "success" };
  if (item.isCompanyLeader)
   return { label: "Company Leader", color: "primary" };
  if (item.isOutletLeader) return { label: "Outlet Leader", color: "warning" };
  return { label: "User", color: "secondary" };
 };

 const columns = useMemo(
  () => [
   ...getNumberColumn(),
   getColumn("Name", "name", "left", true),
   getColumn("ID", "employeeID", "left", false),
   getColumn("Email", "email", "left", false),
   getColumn("Date Created", "createdAt", "right", false, (item) => {
    if (!isValidDate(item)) return "-";
    return `${dayjs(item).format("DD-MMM-YYYY")}`;
   }),
   getColumn("Last Updated", "updatedAt", "left", false, (item) => {
    if (!isValidDate(item)) return "-";
    return (
     <Row className="d-flex align-items-center" style={{ minWidth: "130px" }}>
      <Col md="auto">
       {getTimeAgo(item) || `${dayjs(item).format("DD-MMM-YYYY HH:mm:ss")}`}{" "}
       <TooltipIcon text={`${dayjs(item).format("DD-MMM-YYYY")}`}></TooltipIcon>
      </Col>
     </Row>
    );
   }),
   getColumn("Last Logged In", "lastLoginAt", "right", false, (item) => {
    if (!isValidDate(item)) return "-";
    return `${dayjs(item).format("DD-MMM-YYYY HH:mm")}`;
   }),
   getColumn("Last Seen", "lastSeenAt", "left", false, (item) => {
    if (!isValidDate(item)) return "-";
    return (
     <Row className="d-flex align-items-center">
      <Col style={{ minWidth: "130px" }}>
       {getTimeAgo(item, true) ||
        `${dayjs(item).format("DD-MMM-YYYY HH:mm:ss")}`}{" "}
       <TooltipIcon
        text={`${dayjs(item).format("DD-MMM-YYYY HH:mm:ss")}`}
       ></TooltipIcon>
      </Col>
     </Row>
    );
   }),
   {
    id: "role",
    header: "Role",
    accessorKey: "Role",
    headerAlign: "center",
    cell: ({ row }) => {
     const item = row.original;
     return (
      <Col style={{ minWidth: "80px" }}>
       <Badge bg={getRole(item)?.color}>{getRole(item)?.label}</Badge>
      </Col>
     );
    },
   },
   {
    id: "actions",
    header: "Actions",
    accessorKey: "Actions",
    headerAlign: "center",
    cell: ({ row }) => {
     const item = row.original;
     return (
      <Col style={{ minWidth: "80px" }}>
       <LinkContainer to={`/user/${item?._id}/edit`}>
        <Button variant="light" className="btn-sm">
         <i className="fas fa-edit"></i>
        </Button>
       </LinkContainer>
       <Button
        variant="danger"
        className="btn-sm"
        onClick={() => deleteHandler(item)}
       >
        <i className="fas fa-trash"></i>
       </Button>
      </Col>
     );
    },
   },
  ],
  // eslint-disable-next-line
  []
 );

 return (
  <div>
   <ReusableTable
    columns={columns}
    data={data}
    error={error}
    filterText={filterText}
    setFilterText={setFilterText}
   />
  </div>
 );
};

export default UserListTableView;

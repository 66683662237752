import React, { useState, useCallback } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { CWidgetStatsB } from "@coreui/react";
import dayjs from "dayjs";
import { CChart } from "@coreui/react-chartjs";
import { capitalizeEachWord, stringIncludes, round } from "../../utils/utils";
import EmployeeSummaryModal from "../../components/EmployeeSummaryModal";
import WidgetStats from "../../components/widget/WidgetStats";
import { useSelector } from "react-redux";
import { getLocalStorageSettings } from "../../utils/localStorage.jsx";
import AttendanceSummaryModal from "../../components/AttendanceSummaryModal";
import AttendanceSummaryModalDetails from "../../components/AttendanceSummaryModalDetails";
import AttendanceSummaryModalLembur from "../../components/AttendanceSummaryModalLembur";
import AttendanceSummaryModalDetailsRekap from "../../components/AttendanceSummaryModalDetailsRekap.jsx";
import { getDateRange } from "../../utils/time.jsx";
import AttendanceSummaryModalTunjanganKehadiran from "../../components/AttendanceSummaryModalTunjanganKehadiran.jsx";
import { getArrayValue } from "../../utils/array.jsx";

const AnaliticsOverview = ({ data, allData, date }) => {
 const [modalVisible, setModalVisible] = useState({
  employees: {
   new: false,
   resigned: false,
   probation: false,
   tetap: false,
   kontrak: false,
   onTheJobTraining: false,
   outsourcing: false,
   partTime: false,
   absen: false,
   cuti: false,
   totalLateInDaysMoreThan5Min: false,
   sakit: false,
   izin: false,
   lembur: false,
   lemburLongshift: false,
   lemburHln: false,
   rekapAbsensi: false,
   tunjanganKehadiran: false,
  },
 });
 const { userInfo } = useSelector((state) => state.userLogin);
 const existingOutletSettings = getLocalStorageSettings(
  "settings",
  "showDebug"
 );
 const showDebug = userInfo?.isAdmin ? existingOutletSettings ?? false : false;

 const toggleModal = useCallback((modalName) => {
  setModalVisible((prevState) => {
   // Only update state if the modal visibility actually changes
   const newVisibility = !prevState?.employees[modalName];
   if (newVisibility === prevState?.employees[modalName]) {
    return prevState; // No need to update state
   }

   return {
    employees: {
     ...prevState?.employees,
     [modalName]: newVisibility,
    },
   };
  });
 }, []);

 const totalActiveEmployeesArray = getArrayValue(
  allData,
  "totalActiveEmployees"
 );

 const totalResignedEmployeesArray = getArrayValue(
  allData,
  "totalResignedEmployees"
 );

 const latestTotalResignedEmployees =
  totalResignedEmployeesArray?.slice(-1) ?? 0;
 const latestTotalActiveEmployees = totalActiveEmployeesArray?.slice(-1) ?? 0;

 console.log({ totalActiveEmployeesArray });

 const latestAttendanceRate = round(
  (Number(latestTotalResignedEmployees) / Number(latestTotalActiveEmployees)) *
   100,
  2
 );

 const turnOverRateArray = getArrayValue(allData, "turnOverRate");
 const totalStatusTetapArray = getArrayValue(allData, "totalStatusTetap");
 const totalStatusProbationArray = getArrayValue(
  allData,
  "totalStatusProbation"
 );
 const totalStatusOnTheJobTrainingArray = getArrayValue(
  allData,
  "totalStatusOnTheJobTraining"
 );
 const totalNewEmployeesArray = getArrayValue(allData, "totalNewEmployees");
 const totalMaleArray = getArrayValue(allData, "totalMale");
 const totalFemaleArray = getArrayValue(allData, "totalFemale");

 let monthArray = [];
 if (allData) {
  monthArray = allData?.map(
   (record) => record.date && dayjs(record.date).format("MMM")
  );
 }

 const nextMonth = dayjs(date).add(1, "month").date(25).toDate();

 return (
  <Container>
   <Row>
    <Col>{getDateRange(date, nextMonth, null, true)}</Col>
   </Row>
   <Row className="xs">
    <Card className="normal-card">
     <Card.Body>
      <Row className="xs">
       <Col className="d-flex justify-content-center">
        <strong>{data?.outlet && `${capitalizeEachWord(data?.outlet)}`}</strong>
       </Col>
      </Row>
      <Row className="xs">
       <Col>
        <strong>Employee</strong>
       </Col>
      </Row>
      <Row>
       <Col md="auto">
        <WidgetStats
         title="Active"
         allData={allData}
         onClick={() => toggleModal("active")}
         chartType="line"
         dataKey={["totalActiveEmployees"]}
         widgetColor="dark"
        />
       </Col>
       <Col md="auto">
        <WidgetStats
         title="Resigned"
         allData={allData}
         onClick={() => toggleModal("resigned")}
         chartType="bar"
         dataKey={["totalResignedEmployees"]}
         widgetColor="danger"
        />
       </Col>
       <Col md="auto">
        <WidgetStats
         title="New Hire"
         allData={allData}
         onClick={() => toggleModal("new")}
         chartType="bar"
         dataKey={["totalNewEmployees"]}
         style={{ backgroundColor: "rgb(59, 89, 152)", color: "#FFFFFF" }} // Warna kustom
        />
       </Col>
       <Col md="auto">
        <CChart
         type="doughnut"
         style={{ width: "140px", height: "140px" }}
         data={{
          labels: ["Male", "Female"],
          datasets: [
           {
            backgroundColor: ["red", "pink"],
            data: [data?.totalMale ?? 0, data?.totalFemale ?? 0],
           },
          ],
         }}
         options={{
          plugins: {
           legend: {
            labels: {
             color: "primary",
            },
           },
          },
         }}
        />
       </Col>
       <Col>
        <CChart
         type="line"
         style={{ width: "380px", height: "150px" }}
         data={{
          labels: monthArray,
          datasets: [
           {
            label: "Male",
            backgroundColor: "red",
            borderColor: "red",
            pointBackgroundColor: "white",
            pointBorderColor: "#fff",
            data: totalMaleArray,
           },
           {
            label: "Female",
            backgroundColor: "pink",
            borderColor: "pink",
            pointBackgroundColor: "white",
            pointBorderColor: "#fff",
            data: totalFemaleArray,
           },
          ],
         }}
         options={{
          plugins: {
           legend: {
            labels: {
             color: "danger",
            },
           },
          },
          scales: {
           x: {
            grid: {
             color: "danger",
            },
            ticks: {
             color: "danger",
            },
           },
           y: {
            grid: {
             color: "danger",
            },
            ticks: {
             color: "danger",
            },
           },
          },
         }}
        />
       </Col>
      </Row>
      <Row style={{ fontSize: "70%" }} className="xs mb-2">
       <Col>note: tidak termasuk karyawan outsourcing</Col>
      </Row>
     </Card.Body>
    </Card>
   </Row>
   <Row className="xs mt-4">
    <Card className="normal-card">
     <Card.Body>
      <Row className="xs">
       <Col>
        <strong>Status</strong>
       </Col>
      </Row>
      <Row className="xs">
       <Col md="auto">
        <WidgetStats
         title="Permanent"
         allData={allData}
         onClick={() => toggleModal("tetap")}
         chartType="bar"
         dataKey={["totalStatusTetap"]}
         widgetColor="success"
        />
       </Col>
       <Col md="auto">
        <WidgetStats
         title="Probation"
         allData={allData}
         onClick={() => toggleModal("probation")}
         chartType="bar"
         dataKey={["totalStatusProbation"]}
         widgetColor="warning"
        />
       </Col>
       <Col md="auto">
        <WidgetStats
         title="Contract"
         allData={allData}
         onClick={() => toggleModal("kontrak")}
         chartType="bar"
         dataKey={["totalStatusKontrak"]}
         widgetColor="primary"
        />
       </Col>
       <Col md="auto">
        <WidgetStats
         title="On The Job Training"
         allData={allData}
         onClick={() => toggleModal("onTheJobTraining")}
         chartType="bar"
         dataKey={["totalStatusOnTheJobTraining"]}
         widgetColor="dark"
        />
       </Col>
       <Col md="auto">
        <WidgetStats
         title="Part Time"
         allData={allData}
         onClick={() => toggleModal("partTime")}
         chartType="bar"
         dataKey={["totalStatusPartTime"]}
         widgetColor="warning"
        />
       </Col>
       <Col md="auto">
        <WidgetStats
         title="Outsourcing"
         allData={allData}
         onClick={() => toggleModal("outsourcing")}
         chartType="bar"
         dataKey={["totalStatusOutsourcing"]}
         style={{ backgroundColor: "rgb(77, 77, 77)", color: "#FFFFFF" }} // Warna kustom
        />
       </Col>
      </Row>
      <Row style={{ fontSize: "70%" }}>
       <Col>note: hanya karyawan aktif / belum resign</Col>
      </Row>
     </Card.Body>
    </Card>
   </Row>
   <Row className="xs mt-4">
    <Card className="normal-card">
     <Card.Body>
      <Row className="xs">
       <Col className="d-flex justify-content-center">
        <strong>
         Key Performance Indicator (KPI){" - "}
         {data?.outlet && `${capitalizeEachWord(data?.outlet)}`}
        </strong>
       </Col>
      </Row>
      <Row className="xs">
       <Col>
        <strong>Attendance</strong>
       </Col>
      </Row>
      <Row>
       <Col md="auto">
        <WidgetStats
         title="Absent"
         allData={allData}
         onClick={() => toggleModal("absen")}
         chartType="bar"
         dataKey={["attendanceSummary", "totalKaryawanAbsen"]}
         widgetColor="danger"
        />
       </Col>
       <Col md="auto">
        <WidgetStats
         title="Cuti"
         allData={allData}
         onClick={() => toggleModal("cuti")}
         chartType="bar"
         dataKey={["attendanceSummary", "totalKaryawanCuti"]}
         widgetColor="success"
        />
       </Col>
       <Col md="auto">
        <WidgetStats
         title="Sakit"
         allData={allData}
         onClick={() => toggleModal("sakit")}
         chartType="bar"
         dataKey={["attendanceSummary", "totalKaryawanSakit"]}
         widgetColor="warning"
        />
       </Col>
       <Col md="auto">
        <WidgetStats
         title="Izin"
         allData={allData}
         onClick={() => toggleModal("izin")}
         chartType="bar"
         dataKey={["attendanceSummary", "totalKaryawanIzin"]}
         widgetColor="dark"
        />
       </Col>
       <Col md="auto">
        <WidgetStats
         title="Terlambat lebih dari 5 menit"
         allData={allData}
         onClick={() => toggleModal("totalLateInDaysMoreThan5Min")}
         chartType="bar"
         dataKey={["attendanceSummary", "totalLateInDaysMoreThan5Min"]}
         widgetColor="danger"
        />
       </Col>
      </Row>
      <Row className="xs mt-5">
       <Col md="auto">
        <WidgetStats
         title="Average Attendance Ratio"
         allData={allData}
         onClick={() => toggleModal("rekapAbsensi")}
         chartType="bar"
         dataKey={["attendanceSummary", "averageAttendanceRatio"]}
         style={{ backgroundColor: "rgb(90, 89, 152)", color: "#FFFFFF" }} // Warna kustom
         valueInPercent
        />
       </Col>
       <Col md="auto">
        <WidgetStats
         title="Total Working Hours"
         allData={allData}
         onClick={() => toggleModal("rekapAbsensi")}
         chartType="bar"
         dataKey={["attendanceSummary", "totalWorkingDurationInSeconds"]}
         style={{ backgroundColor: "rgb(59, 89, 152)", color: "#FFFFFF" }} // Warna kustom
        />
       </Col>
       <Col md="auto">
        <WidgetStats
         title="Karyawan Mendapatkan Tunjangan Kehadiran"
         allData={allData}
         onClick={() => toggleModal("tunjanganKehadiran")}
         chartType="bar"
         dataKey={[
          "attendanceSummary",
          "totalKaryawanBerhakTunjanganKehadiran",
         ]}
         widgetColor="success"
        />
       </Col>
      </Row>
      <Row className="xs mt-4">
       <Col>
        <strong>Overtime</strong>
       </Col>
      </Row>
      <Row>
       <Col md="auto">
        <WidgetStats
         title="Karyawan Lembur"
         allData={allData}
         onClick={() => toggleModal("lembur")}
         chartType="bar"
         dataKey={["attendanceSummary", "totalKaryawanLembur"]}
         widgetColor="dark"
        />
       </Col>
       <Col md="auto">
        <WidgetStats
         title="Karyawan Longshift"
         allData={allData}
         onClick={() => toggleModal("lemburLongshift")}
         chartType="bar"
         dataKey={["attendanceSummary", "totalKaryawanLemburLongshift"]}
         widgetColor="success"
        />
       </Col>
       <Col md="auto">
        <WidgetStats
         title="Karyawan Lembur Hari Libur Nasional"
         allData={allData}
         onClick={() => toggleModal("lemburHln")}
         chartType="bar"
         dataKey={["attendanceSummary", "totalKaryawanLemburHln"]}
         widgetColor="danger"
        />
       </Col>
       <Col md="auto">
        <WidgetStats
         title="Total Overtime Hours"
         allData={allData}
         onClick={() => toggleModal("rekapAbsensi")}
         chartType="bar"
         dataKey={["attendanceSummary", "totalOvertimeHours"]}
         widgetColor="dark"
        />
       </Col>
      </Row>
      <Row className="xs mt-4">
       <Col>
        <strong>Turnover</strong>
       </Col>
      </Row>
      <Row className="xs">
       <Col md="auto">
        <CWidgetStatsB
         title="Turnover Rate"
         color="dark"
         inverse
         progress={{
          color:
           latestAttendanceRate <= 10
            ? "success"
            : latestAttendanceRate <= 20
            ? "warning"
            : "danger",
          value: Number(latestAttendanceRate),
         }}
         value={
          <span
           style={{
            color:
             latestAttendanceRate <= 10
              ? "green"
              : latestAttendanceRate <= 20
              ? "orange"
              : "red",
           }}
          >
           {`${latestAttendanceRate}%`}
          </span>
         }
         text={`${latestTotalResignedEmployees}/${Number(
          latestTotalActiveEmployees
         )}`}
        />
       </Col>
       <Col>
        <CChart
         type="bar"
         style={{ width: "380px", height: "170px" }}
         data={{
          labels: monthArray,
          datasets: [
           {
            label: `Turnover Rate${
             data?.month ? ` ${dayjs(data.month).format("YYYY")} (%)` : ""
            }`,
            backgroundColor: "#f87979",
            data: turnOverRateArray,
           },
          ],
         }}
         labels="months"
         options={{
          plugins: {
           legend: {
            labels: {
             color: "danger",
            },
           },
          },
          scales: {
           x: {
            grid: {
             color: "danger",
            },
            ticks: {
             color: "danger",
            },
           },
           y: {
            grid: {
             color: "danger",
            },
            ticks: {
             color: "danger",
            },
           },
          },
         }}
        />
       </Col>
      </Row>
     </Card.Body>
    </Card>
   </Row>
   <Row className="mt-5">
    <CChart
     type="bar"
     style={{ width: "40%", height: "300px" }} // Width adjusts to 40% of the parent container
     data={{
      labels: monthArray,
      datasets: [
       {
        label: `Active Employees${
         data?.month ? ` ${dayjs(data.month).format("YYYY")}` : ""
        }`,
        backgroundColor: "#17a2b8",
        data: totalActiveEmployeesArray,
       },
      ],
     }}
     labels="months"
     options={{
      plugins: {
       legend: {
        labels: {
         color: "danger",
        },
       },
      },
      scales: {
       x: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
       y: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
      },
     }}
    />
    <CChart
     type="bar"
     style={{ width: "40%", height: "300px" }} // Width adjusts to 40% of the parent container
     data={{
      labels: monthArray,
      datasets: [
       {
        label: `Resigned Employees${
         data?.month ? ` ${dayjs(data.month).format("YYYY")}` : ""
        }`,
        backgroundColor: "#f87979",
        data: totalResignedEmployeesArray,
       },
      ],
     }}
     labels="months"
     options={{
      plugins: {
       legend: {
        labels: {
         color: "danger",
        },
       },
      },
      scales: {
       x: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
       y: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
      },
     }}
    />
   </Row>
   <Row>
    <CChart
     type="bar"
     style={{ width: "40%", height: "300px" }} // Width adjusts to 40% of the parent container
     data={{
      labels: monthArray,
      datasets: [
       {
        label: `Permanent Employees${
         data?.month ? ` ${dayjs(data.month).format("YYYY")}` : ""
        }`,
        backgroundColor: "green",
        data: totalStatusTetapArray,
       },
      ],
     }}
     labels="months"
     options={{
      plugins: {
       legend: {
        labels: {
         color: "danger",
        },
       },
      },
      scales: {
       x: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
       y: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
      },
     }}
    />
    <CChart
     type="bar"
     style={{ width: "40%", height: "300px" }} // Width adjusts to 40% of the parent container
     data={{
      labels: monthArray,
      datasets: [
       {
        label: `Probation Employees${
         data?.month ? ` ${dayjs(data.month).format("YYYY")}` : ""
        }`,
        backgroundColor: "orange",
        data: totalStatusProbationArray,
       },
      ],
     }}
     labels="months"
     options={{
      plugins: {
       legend: {
        labels: {
         color: "danger",
        },
       },
      },
      scales: {
       x: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
       y: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
      },
     }}
    />
   </Row>
   <Row>
    <CChart
     type="bar"
     style={{ width: "40%", height: "300px" }} // Width adjusts to 40% of the parent container
     data={{
      labels: monthArray,
      datasets: [
       {
        label: `On The Job Training Employees${
         data?.month ? ` ${dayjs(data.month).format("YYYY")}` : ""
        }`,
        backgroundColor: "gray",
        data: totalStatusOnTheJobTrainingArray,
       },
      ],
     }}
     labels="months"
     options={{
      plugins: {
       legend: {
        labels: {
         color: "danger",
        },
       },
      },
      scales: {
       x: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
       y: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
      },
     }}
    />
    <CChart
     type="bar"
     style={{ width: "40%", height: "300px" }} // Width adjusts to 40% of the parent container
     data={{
      labels: monthArray,
      datasets: [
       {
        label: `New Hire Employees${
         data?.month ? ` ${dayjs(data.month).format("YYYY")}` : ""
        }`,
        backgroundColor: "lightblue",
        data: totalNewEmployeesArray,
       },
      ],
     }}
     labels="months"
     options={{
      plugins: {
       legend: {
        labels: {
         color: "danger",
        },
       },
      },
      scales: {
       x: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
       y: {
        grid: {
         color: "danger",
        },
        ticks: {
         color: "danger",
        },
       },
      },
     }}
    />
   </Row>
   {showDebug && (
    <Card>
     <Card.Body>
      <Row>
       <Col>response</Col>
      </Row>
      <Row>
       <Col>
        <pre>{JSON.stringify(allData, null, 2)} </pre>
       </Col>
      </Row>
     </Card.Body>
    </Card>
   )}
   <EmployeeSummaryModal
    Title="Active Employees"
    employees={data?.employees?.active}
    visible={modalVisible?.employees?.active}
    onClose={() => {
     if (modalVisible?.employees?.active) {
      toggleModal("active");
     }
    }}
   />
   <EmployeeSummaryModal
    Title="Resigned"
    employees={data?.employees?.resigned}
    visible={modalVisible?.employees?.resigned}
    onClose={() => {
     if (modalVisible?.employees?.resigned) {
      toggleModal("resigned");
     }
    }}
   />
   <EmployeeSummaryModal
    Title="New Employees"
    employees={data?.employees?.new}
    visible={modalVisible?.employees?.new}
    onClose={() => {
     if (modalVisible?.employees?.new) {
      toggleModal("new");
     }
    }}
   />
   <EmployeeSummaryModal
    Title="Permanent"
    employees={data?.employees?.active?.filter((item) =>
     stringIncludes(item?.status, "tetap")
    )}
    visible={modalVisible?.employees?.tetap}
    onClose={() => {
     if (modalVisible?.employees?.tetap) {
      toggleModal("tetap");
     }
    }}
   />
   <EmployeeSummaryModal
    Title="Probation"
    employees={data?.employees?.active?.filter((item) =>
     stringIncludes(item?.status, "probation")
    )}
    visible={modalVisible?.employees?.probation}
    onClose={() => {
     if (modalVisible?.employees?.probation) {
      toggleModal("probation");
     }
    }}
   />
   <EmployeeSummaryModal
    Title="Contract"
    employees={data?.employees?.active?.filter((item) =>
     stringIncludes(item?.status, "kontrak")
    )}
    visible={modalVisible?.employees?.kontrak}
    onClose={() => {
     if (modalVisible?.employees?.kontrak) {
      toggleModal("kontrak");
     }
    }}
   />
   <EmployeeSummaryModal
    Title="On The Job Training"
    employees={data?.employees?.active?.filter((item) =>
     stringIncludes(item?.status, "on the job training")
    )}
    visible={modalVisible?.employees?.onTheJobTraining}
    onClose={() => {
     if (modalVisible?.employees?.onTheJobTraining) {
      toggleModal("onTheJobTraining");
     }
    }}
   />
   <EmployeeSummaryModal
    Title="Part Time"
    employees={data?.employees?.active?.filter((item) =>
     stringIncludes(item?.status, "part time")
    )}
    visible={modalVisible?.employees?.partTime}
    onClose={() => {
     if (modalVisible?.employees?.partTime) {
      toggleModal("partTime");
     }
    }}
   />
   <EmployeeSummaryModal
    Title="Outsourcing"
    employees={data?.employees?.outsourcing}
    visible={modalVisible?.employees?.outsourcing}
    onClose={() => {
     if (modalVisible?.employees?.outsourcing) {
      toggleModal("outsourcing");
     }
    }}
   />
   <AttendanceSummaryModal
    Title="Absent"
    employees={data?.attendanceSummary?.listAbsen}
    visible={modalVisible?.employees?.absen}
    onClose={() => {
     if (modalVisible?.employees?.absen) {
      toggleModal("absen");
     }
    }}
   />
   <AttendanceSummaryModal
    Title="Cuti"
    employees={data?.attendanceSummary?.listCuti}
    visible={modalVisible?.employees?.cuti}
    onClose={() => {
     if (modalVisible?.employees?.cuti) {
      toggleModal("cuti");
     }
    }}
   />
   <AttendanceSummaryModalDetails
    Title="Late More Than 5 Minutes"
    employees={data?.attendanceSummary?.listLateInDaysMoreThan5Min}
    visible={modalVisible?.employees?.totalLateInDaysMoreThan5Min}
    onClose={() => {
     if (modalVisible?.employees?.totalLateInDaysMoreThan5Min) {
      toggleModal("totalLateInDaysMoreThan5Min");
     }
    }}
   />
   <AttendanceSummaryModalDetails
    Title="Sakit"
    employees={data?.attendanceSummary?.listSakit}
    visible={modalVisible?.employees?.sakit}
    onClose={() => {
     if (modalVisible?.employees?.sakit) {
      toggleModal("sakit");
     }
    }}
   />
   <AttendanceSummaryModalDetails
    Title="Izin"
    employees={data?.attendanceSummary?.listIzin}
    visible={modalVisible?.employees?.izin}
    onClose={() => {
     if (modalVisible?.employees?.izin) {
      toggleModal("izin");
     }
    }}
   />
   <AttendanceSummaryModalLembur
    Title="Overtime Hours"
    employees={data?.attendanceSummary?.listLembur}
    visible={modalVisible?.employees?.lembur}
    onClose={() => {
     if (modalVisible?.employees?.lembur) {
      toggleModal("lembur");
     }
    }}
   />
   <AttendanceSummaryModalLembur
    Title="Overtime Longshift"
    employees={data?.attendanceSummary?.listLemburLongshift}
    visible={modalVisible?.employees?.lemburLongshift}
    onClose={() => {
     if (modalVisible?.employees?.lemburLongshift) {
      toggleModal("lemburLongshift");
     }
    }}
   />
   <AttendanceSummaryModalLembur
    Title="Overtime Hari Libur Nasional"
    employees={data?.attendanceSummary?.listLemburHln}
    visible={modalVisible?.employees?.lemburHln}
    onClose={() => {
     if (modalVisible?.employees?.lemburHln) {
      toggleModal("lemburHln");
     }
    }}
   />
   <AttendanceSummaryModalDetailsRekap
    Title="Attendance Summary / Rekap Absensi Karyawan"
    startDate={data?.month}
    employees={data?.attendanceSummary?.listAttendanceSummaryDetails}
    visible={modalVisible?.employees?.rekapAbsensi}
    onClose={() => {
     if (modalVisible?.employees?.rekapAbsensi) {
      toggleModal("rekapAbsensi");
     }
    }}
   />
   <AttendanceSummaryModalTunjanganKehadiran
    Title="Karyawan Berhak Mendapatkan Tunjangan Kehadiran"
    startDate={data?.month}
    employees={data?.attendanceSummary?.listBerhakTunjanganKehadiran}
    visible={modalVisible?.employees?.tunjanganKehadiran}
    onClose={() => {
     if (modalVisible?.employees?.tunjanganKehadiran) {
      toggleModal("tunjanganKehadiran");
     }
    }}
   />
  </Container>
 );
};

export default AnaliticsOverview;

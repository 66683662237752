import {
 ATTENDANCE_SUMMARY_DATA_ENTER_FAIL,
 ATTENDANCE_SUMMARY_DATA_ENTER_REQUEST,
 ATTENDANCE_SUMMARY_DATA_ENTER_SUCCESS,
 ATTENDANCE_SUMMARY_DELETE_REQUEST,
 ATTENDANCE_SUMMARY_DELETE_SUCCESS,
 ATTENDANCE_SUMMARY_DELETE_FAIL,
 ATTENDANCE_SUMMARY_DETAILS_REQUEST,
 ATTENDANCE_SUMMARY_DETAILS_SUCCESS,
 ATTENDANCE_SUMMARY_DETAILS_ERROR,
 ATTENDANCE_SUMMARY_UPDATE_REQUEST,
 ATTENDANCE_SUMMARY_UPDATE_SUCCESS,
 ATTENDANCE_SUMMARY_UPDATE_ERROR,
} from "../constants/attendanceSummaryConstant";
import axios from "axios";
import store from "../store";
import { getAxiosTargetPath } from "../utils/utils";

export const getAttendanceSummaryUpdate =
 (startDate) => async (dispatch, getState) => {
  try {
   dispatch({ type: ATTENDANCE_SUMMARY_UPDATE_REQUEST });
   const {
    userLogin: { userInfo },
   } = getState();
   const config = {
    headers: {
     Authorization: `Bearer ${userInfo?.token}`,
    },
   };

   const { data } = await axios.get(
    `/attendanceSummary/update/${startDate}`,
    config
   );

   dispatch({
    type: ATTENDANCE_SUMMARY_UPDATE_SUCCESS,
    payload: data,
   });
  } catch (error) {
   dispatch({
    type: ATTENDANCE_SUMMARY_UPDATE_ERROR,
    payload:
     error.response && error.response.data.message
      ? error.response.data.message
      : error.message,
   });
  }
 };

export const setAttendanceSummaryUpdateForOneEmployeeId =
 (attendanceSummary) => async (dispatch, getState) => {
  try {
   dispatch({ type: ATTENDANCE_SUMMARY_UPDATE_REQUEST });
   const {
    userLogin: { userInfo },
   } = getState();
   const config = {
    headers: {
     Authorization: `Bearer ${userInfo?.token}`,
    },
   };

   const { data } = await axios.put(
    `/attendanceSummary/update/oneEmployeeId`,
    attendanceSummary,
    config
   );

   dispatch({
    type: ATTENDANCE_SUMMARY_UPDATE_SUCCESS,
    payload: data,
   });
  } catch (error) {
   dispatch({
    type: ATTENDANCE_SUMMARY_UPDATE_ERROR,
    payload:
     error.response && error.response.data.message
      ? error.response.data.message
      : error.message,
   });
  }
 };

export const postAttendanceSummary =
 (attendanceSummary) => async (dispatch, getState) => {
  try {
   dispatch({ type: ATTENDANCE_SUMMARY_DATA_ENTER_REQUEST });

   const {
    userLogin: { userInfo },
   } = getState();

   const config = {
    headers: {
     Authorization: `Bearer ${userInfo?.token}`,
    },
   };

   // Tentukan ukuran maksimal untuk setiap array JSON yang akan dikirim
   const MAX_ARRAY_SIZE = 200; // Contoh batas maksimal array
   const attendanceSummaryChunks = [];

   // Memecah data attendance jika melebihi batas maksimal
   for (let i = 0; i < attendanceSummary.length; i += MAX_ARRAY_SIZE) {
    attendanceSummaryChunks.push(
     attendanceSummary.slice(i, i + MAX_ARRAY_SIZE)
    );
   }

   // Kirim setiap chunk secara terpisah
   for (const chunk of attendanceSummaryChunks) {
    const { data } = await axios.post(`/attendanceSummary/post`, chunk, config);
    dispatch({
     type: ATTENDANCE_SUMMARY_DATA_ENTER_SUCCESS,
     payload: data,
    });
   }
  } catch (error) {
   dispatch({
    type: ATTENDANCE_SUMMARY_DATA_ENTER_FAIL,
    payload:
     error.response && error.response.data.message
      ? error.response.data.message
      : error.message,
   });
  }
 };

export const getAnalysisAttendanceSummaryByDate = async ({ queryKey }) => {
 const [, { isActiveEmployee, keyword, outlet, date }] = queryKey;
 const {
  userLogin: { userInfo },
 } = store?.getState();
 const config = {
  headers: {
   Authorization: `Bearer ${userInfo?.token}`,
  },
 };
 const axiosPath = getAxiosTargetPath(
  `/attendanceSummary/getAnalysis/?isActiveEmployee=${isActiveEmployee}&keyword=${
   keyword ? keyword : ""
  }&outlet=${outlet ? outlet : ""}&date=${date}`
 );
 const { data } = await axios.get(axiosPath, config);
 return data;
};

export const deleteAttendanceSummaryByDate =
 (days) => async (dispatch, getState) => {
  try {
   dispatch({ type: ATTENDANCE_SUMMARY_DELETE_REQUEST });
   const {
    userLogin: { userInfo },
   } = getState();
   const config = {
    headers: {
     Authorization: `Bearer ${userInfo?.token}`,
    },
   };
   const { data } = await axios.delete(`/attendanceSummary/${days}`, config);

   dispatch({
    type: ATTENDANCE_SUMMARY_DELETE_SUCCESS,
    payload: data,
   });
  } catch (error) {
   dispatch({
    type: ATTENDANCE_SUMMARY_DELETE_FAIL,
    payload:
     error.response && error.response.data.message
      ? error.response.data.message
      : error.message,
   });
  }
 };

export const getAttendanceSummaryDetails =
 (id) => async (dispatch, getState) => {
  try {
   dispatch({ type: ATTENDANCE_SUMMARY_DETAILS_REQUEST });
   const {
    userLogin: { userInfo },
   } = getState();
   const config = {
    headers: {
     Authorization: `Bearer ${userInfo?.token}`,
    },
   };

   const { data } = await axios.get(`/attendanceSummary/${id}`, config);

   dispatch({
    type: ATTENDANCE_SUMMARY_DETAILS_SUCCESS,
    payload: data,
   });
  } catch (error) {
   dispatch({
    type: ATTENDANCE_SUMMARY_DETAILS_ERROR,
    payload:
     error.response && error.response.data.message
      ? error.response.data.message
      : error.message,
   });
  }
 };

/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Employee from "../../components/Employee";
import Loader from "../../components/Loader.jsx";
import Message from "../../components/Message.jsx";
import { getEmployees } from "../../actions/employeeActions";
import { Route, useLocation } from "react-router-dom";
import {
 Row,
 Col,
 ToggleButtonGroup,
 ToggleButton,
 Form,
 Button,
 Container,
} from "react-bootstrap";
import { capitalizeEachWord, stringIncludes } from "../../utils/utils.jsx";
import { getSheetDetails } from "../../actions/sheetActions";
import { employeeListTabs } from "../../data/etc.jsx";
import Tab from "../../components/Tab";
import {
 getLocalStorageSettings,
 updateLocalStorageSettings,
} from "../../utils/localStorage";
import FormSelect from "../../components/select/FormSelect";
import { getAllOutlet } from "../../actions/outletActions";
import LatestEmployeeUpdated from "../../components/employee/LatestEmployeeUpdated";
import { useQuery } from "@tanstack/react-query";
import EmployeeTableBpjsView from "./EmployeeTableBpjsView";
import EmployeeTableGeneralView from "./EmployeeTableGeneralView";
import EmployeeTableBankView from "./EmployeeTableBankView";
import SearchBox from "../../components/SearchBox";
import EmployeeTableContactView from "./EmployeeTableContactView";
import EmployeeTableAgeView from "./EmployeeTableAgeView";
import EmployeeTableScheduleView from "./EmployeeTableScheduleView";
import EmployeeCountByStatus from "../../components/employee/EmployeeCountByStatus";
import DownloadEmployeeToExcel from "../../components/DownloadEmployeeToExcel.jsx";
import Popup from "../../components/Popup";
import DownloadButton from "../../components/DownloadButton";
import iconExcelDownload from "../../Assets/icon/icon_excel_download.png";
import ReusableFormSelect from "../../components/select/ReusableFormSelect";
import { getAllStatus } from "../../actions/statusActions";
import { MdPersonAdd } from "react-icons/md";
import { Icon } from "@rsuite/icons";
import { LinkContainer } from "react-router-bootstrap";

const EmployeeListView = ({ match, history }) => {
 const existingOutletSettings = getLocalStorageSettings(
  "employeeList",
  "outlet"
 );
 const existingTabSettings = getLocalStorageSettings("employeeList", "tab");

 const location = useLocation(); // Added for reading the URL
 //const keyword = match.params.keyword;
 const [keyword, setKeyword] = useState("");

 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, userInfo } = userLogin;
 const token = userInfo?.token;

 const [outlet, setOutlet] = useState(
  userInfo?.isAdmin || userInfo?.isCompanyLeader
   ? existingOutletSettings ?? userInfo?.outlet
   : userInfo?.outlet
 );
 const [prevOutlet, setPrevOutlet] = useState(outlet);
 const [filterText, setFilterText] = useState("");
 const [tab, setTab] = useState(
  new URLSearchParams(location.search).get("tab") ||
   existingTabSettings ||
   "grid"
 );
 const [showDownloadExcelPopup, setShowDownloadExcelPopup] = useState(false);
 const [status, setStatus] = useState("ALL STATUS");

 const outletList = useSelector((state) => state.outletList);
 const {
  loading: outletListLoading,
  error: errorOutlets,
  outlets,
 } = outletList;

 const [outletRegion, setOutletRegion] = useState("sumatera utara");
 const [groupSriwijaya, setGroupSriwijaya] = useState(false);
 const [religion, setReligion] = useState("all religions");

 const {
  data,
  isLoading,
  error: errorQuery,
 } = useQuery({
  queryKey: ["employees", { keyword, outlet }], // Query key as an array
  queryFn: getEmployees, // Function to fetch data
  enabled: token && !outletListLoading ? true : false, // Only fetch if token is available
  staleTime: 1000 * 60, // Data considered fresh for 60 seconds (60000 ms)
  refetchInterval: stringIncludes(tab, "grid") ? 1000 * 10 : 1000 * 20,
  retry: false, // Disable retries on failure
  refetchOnError: false, // Disable automatic refetch when there's an error
 });

 const employees = data?.employees || [];

 const {
  data: dataSheet,
  isLoading: isLoadingSheet,
  error: errorSheet,
 } = useQuery({
  queryKey: ["getSheetDetails", { name: "latestEmployeeUpdate" }], // Query key as an array
  queryFn: getSheetDetails, // Function to fetch data
  enabled: token ? true : false, // Only fetch if token is available
  staleTime: 1000 * 60, // Data considered fresh for 60 seconds (60000 ms)
  refetchInterval: 1000 * 20,
  retry: false, // Disable retries on failure
  refetchOnError: false, // Disable automatic refetch when there's an error
 });

 const sheet = dataSheet || dataSheet?.sheet || [];
 const error =
  errorQuery?.response?.data?.message ||
  errorQuery?.message ||
  errorSheet?.response?.data?.message ||
  errorSheet?.message ||
  "";

 const existingIsActiveSettings = getLocalStorageSettings(
  "employeeList",
  "isActive"
 );

 const [isActiveEmployee, setIsActiveEmployee] = useState(
  existingIsActiveSettings ?? true
 );

 //console.log(`isActiveEmployee: ${isActiveEmployee}`);
 const handleOutletChange = (e) => {
  const selectedOutlet = e?.target?.value;
  if (!selectedOutlet) return;

  setOutlet(selectedOutlet);
  updateLocalStorageSettings("employeeList", "outlet", selectedOutlet);
 };

 const handleIsActiveEmployee = (e) => {
  const selected = e?.target?.value;
  if (selected !== undefined) {
   setIsActiveEmployee(selected === "true"); // Converts string to boolean
   updateLocalStorageSettings("employeeList", "isActive", selected);
  }
 };

 const handleTabChange = (e) => {
  const selectedTab = e.target.value;
  setTab(selectedTab);

  // Update the URL with the selected outlet
  const searchParams = new URLSearchParams(location.search);
  searchParams.set("tab", selectedTab);
  history.push({ search: searchParams.toString() });
 };

 const dispatch = useDispatch();

 const statusList = useSelector((state) => state.statusList);
 const {
  loading: loadingAllStatus,
  error: errorAllStatus,
  status: allStatus,
 } = statusList;

 useEffect(() => {
  if ((!userLoading && !userInfo) || stringIncludes(error, "not authorized")) {
   dispatch(logout());
   history.push("/login");
  }

  // Update outlet state from URL if present
  const searchParams = new URLSearchParams(location.search);
  const outletParam = searchParams.get("outlet");
  if (outletParam) {
   setOutlet(outletParam); // Set the outlet from URL
  }

  if (tab) {
   updateLocalStorageSettings(`employeeList`, `tab`, tab);
  }

  // Check if the tab has changed
  const isOutletChanged = outlet !== prevOutlet; // You need to maintain a `prevTab` state to compare
  if (isOutletChanged) {
   updateLocalStorageSettings(`employeeList`, `outlet`, outlet);
   setPrevOutlet(outlet);
  }

  // Update keyword state from URL if present
  const keywordParam = searchParams.get("keyword") ?? "";
  setKeyword(keywordParam); // Set the keyword from URL

  if (!outletListLoading && !outlets?.length && !errorOutlets) {
   dispatch(getAllOutlet());
  }

  const existingIsActiveSettings = getLocalStorageSettings(
   "employeeList",
   "isActive"
  );
  if (existingIsActiveSettings !== undefined) {
   setIsActiveEmployee(existingIsActiveSettings === "true");
  }

  if (!loadingAllStatus && !errorAllStatus && !allStatus?.length) {
   dispatch(getAllStatus());
  }
 }, [userInfo, outlet, tab, employees, sheet, keyword]);

 const getTable = () => {
  switch (tab) {
   case "table general":
    return (
     <>
      <EmployeeTableGeneralView
       isActiveEmployee={isActiveEmployee}
       outlet={outlet}
       employees={employees}
       error={error}
       setFilterText={setFilterText}
       filterText={filterText}
      />
     </>
    );

   case "table bpjs":
    return (
     <>
      <EmployeeTableBpjsView
       isActiveEmployee={isActiveEmployee}
       outlet={outlet}
       employees={employees}
       error={error}
       setFilterText={setFilterText}
       filterText={filterText}
      />
     </>
    );

   case "table bank":
    return (
     <>
      <EmployeeTableBankView
       isActiveEmployee={isActiveEmployee}
       outlet={outlet}
       employees={employees}
       error={error}
       setFilterText={setFilterText}
       filterText={filterText}
      />
     </>
    );

   case "table contact":
    return (
     <>
      <EmployeeTableContactView
       isActiveEmployee={isActiveEmployee}
       outlet={outlet}
       employees={employees}
       error={error}
       setFilterText={setFilterText}
       filterText={filterText}
      />
     </>
    );

   case "table age":
    return (
     <>
      <EmployeeTableAgeView
       isActiveEmployee={isActiveEmployee}
       outlet={outlet}
       employees={employees}
       error={error}
       setFilterText={setFilterText}
       filterText={filterText}
      />
     </>
    );

   case "table schedule":
    return (
     <>
      <EmployeeTableScheduleView
       isActiveEmployee={isActiveEmployee}
       outlet={outlet}
       employees={employees}
       error={error}
       setFilterText={setFilterText}
       filterText={filterText}
      />
     </>
    );
  }
 };

 return (
  <Container>
   <>
    <Row className="d-flex align-items-end">
     <Col>
      <h5>{`Employees ${capitalizeEachWord(outlet)}`}</h5>
     </Col>

     {userInfo?.isAdmin ||
     userInfo?.isCompanyLeader ||
     userInfo?.isOutletLeader ? (
      <Col className="d-flex justify-content-end">
       <Popup
        show={showDownloadExcelPopup}
        onHide={() => setShowDownloadExcelPopup(false)}
        title="Dowload Employee List"
        onApply={() => {
         DownloadEmployeeToExcel(
          employees,
          outlets,
          outlet,
          status,
          outletRegion,
          groupSriwijaya,
          religion
         );
        }}
        applyLabel="Download"
       >
        <Row>
         <Col>
          <ReusableFormSelect
           formLabel="Region"
           value={outletRegion}
           onChangeFn={(e) => setOutletRegion(e?.target?.value)}
           options={
            outlets?.length > 1 && stringIncludes(outlet, "all outlets")
             ? [...new Set(outlets?.map((outlet) => outlet?.region))]
             : outlets?.length > 1 && !stringIncludes(outlet, "all outlets")
             ? [
                ...new Set(
                 outlets?.map((o) => {
                  if (stringIncludes(o?.name, outlet)) return o?.region;
                 })
                ),
               ]
             : [outlet].map((outlet) => outlet?.region)
           }
           disabled={outlets && outlets?.length ? false : true}
          />
         </Col>
         <Col>
          <ReusableFormSelect
           formLabel="Status Karyawan"
           value={status}
           onChangeFn={(e) => setStatus(e?.target?.value)}
           options={[
            "ALL STATUS",
            ...allStatus?.map((status) => status?.name), // Spread the result of the map function directly into the array
           ]}
          />
         </Col>
        </Row>
        <Row className="mt-3">
         <Col>
          <Form.Label>
           Gabungkan Management dan Khalid Medan ke sheet Sriwijaya
          </Form.Label>
         </Col>
        </Row>
        <Row>
         <Col>
          <ToggleButtonGroup type="radio" name="options">
           <ToggleButton
            key="groupSriwijaya"
            id="groupSriwijaya"
            value={true}
            name="radio"
            onChange={() => setGroupSriwijaya(true)}
            style={{
             backgroundColor: groupSriwijaya === true ? "#dc3545" : "#6c757d",
             border: "none", // Remove the outline
            }}
           >
            Ya
           </ToggleButton>
           <ToggleButton
            key="ungroupSriwijaya"
            id="ungroupSriwijaya"
            value={false}
            name="radio"
            onChange={() => setGroupSriwijaya(false)}
            style={{
             backgroundColor: groupSriwijaya === false ? "#dc3545" : "#6c757d",
             border: "none", // Remove the outline
            }}
           >
            Tidak
           </ToggleButton>
          </ToggleButtonGroup>
         </Col>
        </Row>
        <Row>
         <Col>
          <ReusableFormSelect
           formLabel="Agama"
           value={religion}
           onChangeFn={(e) => setReligion(e?.target?.value)}
           options={
            employees?.length > 1
             ? [
                "all religions",
                ...new Set(employees?.map((employee) => employee?.religion)),
               ]
             : ["all religions"]
           }
           disabled={
            [...new Set(employees?.map((employee) => employee?.religion))]
             ?.length
             ? false
             : true
           }
          />
         </Col>
        </Row>
       </Popup>
       <DownloadButton
        image={iconExcelDownload}
        onClickFn={() => setShowDownloadExcelPopup(true)}
        alt="Download Excel"
       />
      </Col>
     ) : (
      ""
     )}
    </Row>

    <Row className="justify-content-md-left">
     <Col>
      <Tab
       tabs={employeeListTabs}
       tab={tab}
       setTab={handleTabChange}
       defaultSetTab={false}
       rowClassName="justify-content-md-left"
      />
     </Col>
    </Row>

    <Row className="mt-3 justify-content-between">
     {userInfo?.isAdmin && (
      <Col md="auto">
       <LinkContainer to="/addEmployee" style={{ textDecoration: "none" }}>
        <Button variant="danger" disabled={isLoading} className="custom-button">
         <Icon as={MdPersonAdd} style={{ marginRight: "5px" }} />
         Add employee
        </Button>
       </LinkContainer>
      </Col>
     )}

     <Col md="auto">
      <ToggleButtonGroup type="radio" name="options">
       <ToggleButton
        key="isActiveEmployee"
        id="isActiveEmployeeRadio1"
        value="true"
        name="radio"
        onChange={handleIsActiveEmployee}
        style={{
         backgroundColor: isActiveEmployee === true ? "#dc3545" : "#6c757d",
         border: "none", // Remove the outline
        }}
        disabled={isLoading}
       >
        Active
       </ToggleButton>
       <ToggleButton
        key="resignEmployee"
        id="isActiveEmployeeRadio2"
        value="false"
        name="radio"
        onChange={handleIsActiveEmployee}
        style={{
         backgroundColor: isActiveEmployee === false ? "#dc3545" : "#6c757d",
         border: "none", // Remove the outline
        }}
        disabled={isLoading}
       >
        Resigned
       </ToggleButton>
      </ToggleButtonGroup>
     </Col>
     {userInfo?.isAdmin || userInfo?.isCompanyLeader ? (
      <FormSelect
       controlId="outlet"
       value={outlet || ""}
       onChange={handleOutletChange}
       options={outlets || ""}
       defaultLabel={false}
       customLabel="ALL OUTLETS"
       disabled={isLoading}
      />
     ) : (
      <></>
     )}
     <EmployeeCountByStatus employee={employees} />
     {tab && stringIncludes(tab, "grid") && (
      <Col md="auto">
       <SearchBox
        pageName={"employeeList"}
        history={history}
        keyword={keyword}
        outlet={outlet}
        isActiveEmployee={isActiveEmployee}
        userInfo={userInfo}
        tab={tab}
       />
      </Col>
     )}
     {tab && !stringIncludes(tab, "grid") && (
      <Col md={4}>
       <Form.Control
        type="text"
        placeholder="Filter by id, name, position, status, etc."
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
       />
      </Col>
     )}
    </Row>
    {sheet && (
     <Row style={{ fontSize: "80%" }}>
      {sheet && <LatestEmployeeUpdated sheet={sheet} />}
     </Row>
    )}
   </>

   {isLoading || outletListLoading || isLoadingSheet ? (
    <Loader />
   ) : error || errorOutlets ? (
    <Message variant="danger">{error}</Message>
   ) : stringIncludes(tab, "grid") ? (
    <>
     <Row>
      {userInfo?.isAdmin ||
      userInfo?.isOutletLeader ||
      userInfo?.isCompanyLeader ? (
       employees &&
       employees?.length > 0 &&
       employees?.map((employee) =>
        isActiveEmployee ? (
         (!employee.dateResign && outlet === employee.outlet) ||
         (!employee.dateResign && outlet === "ALL OUTLETS") ? (
          <Col key={employee._id} sm={12} md={6} lg={4} xl={3}>
           <Employee employee={employee} />
          </Col>
         ) : null
        ) : (employee.dateResign && outlet === employee.outlet) ||
          (employee.dateResign && outlet === "ALL OUTLETS") ? (
         <Col key={employee._id} sm={12} md={6} lg={4} xl={3}>
          <Employee employee={employee} />
         </Col>
        ) : null
       )
      ) : (
       <Col key={employees?._id} sm={12} md={6} lg={4} xl={3}>
        <Employee employee={employees} />
       </Col>
      )}
     </Row>
    </>
   ) : (
    getTable()
   )}
  </Container>
 );
};

export default EmployeeListView;

import React, { useState } from "react";
import { Form, Row, Col, Button, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Message from "../../components/Message";
import FormContainer from "../../components/FormContainer";
import { Link } from "react-router-dom";
import { resetPassword } from "../../actions/userActions";
import { useDispatch } from "react-redux";

const ResetPassword = ({ location, history }) => {
 const { token } = useParams(); // Ambil token dari URL
 const dispatch = useDispatch();

 const [password, setPassword] = useState("");
 const [confirmPassword, setConfirmPassword] = useState("");
 const [message, setMessage] = useState("");
 const [error, setError] = useState("");

 const submitHandler = async (e) => {
  e.preventDefault();
  setError("");
  setMessage("");

  if (password !== confirmPassword) {
   setError("Passwords do not match!");
   return;
  }

  dispatch(resetPassword(token, password));
  history.push("/login");
 };

 return (
  <Container>
   <FormContainer>
    <h3>Reset Password</h3>
    {error && <Message variant="danger">{error}</Message>}
    {message && <Message variant="success">{message}</Message>}
    <Form>
     <Form.Group controlId="password">
      <Form.Label>New Password</Form.Label>
      <Form.Control
       type="password"
       placeholder="Enter new password"
       value={password}
       onChange={(e) => setPassword(e.target.value)}
       required
      ></Form.Control>
     </Form.Group>

     <Form.Group controlId="confirmPassword">
      <Form.Label>Confirm Password</Form.Label>
      <Form.Control
       type="password"
       placeholder="Confirm new password"
       value={confirmPassword}
       onChange={(e) => setConfirmPassword(e.target.value)}
       required
      ></Form.Control>
     </Form.Group>

     <Button
      type="submit"
      variant="danger"
      onClick={submitHandler}
      className="mt-3 mb-3"
     >
      Reset Password
     </Button>
    </Form>

    <Row className="mb-3">
     <Col>
      Remembered your password? <Link to="/login">Login</Link>
     </Col>
    </Row>
   </FormContainer>
  </Container>
 );
};

export default ResetPassword;

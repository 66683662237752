import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";
import { useSelector } from "react-redux";
import AddEmployeeView from "./screens/AddEmployeeView";
import AnalysisView from "./screens/AnalysisView";
import LoginView from "./screens/authentication/LoginView";
import RegisterView from "./screens/authentication/RegisterView";
import EmployeeDetailsView from "./screens/EmployeeDetailsView";
import ProfileView from "./screens/ProfileView";
import UserListView from "./screens/UserListView";
import UserEditView from "./screens/UserEditView";
import EmployeeListView from "./screens/employeeList/EmployeeListView";
import SettingsView from "./screens/SettingsView";
import AttendanceView from "./screens/attendance/AttendanceView";
import ForgotPasswordView from "./screens/authentication/ForgotPasswordView";
import ResetPasswordView from "./screens/authentication/ResetPasswordView";
import OutletListView from "./screens/outletList/OutletListView";
import AddOutletView from "./screens/AddOutletView";
import DashboardView from "./screens/dashboard/DashboardView";

const App = () => {
 const userLogin = useSelector((state) => state.userLogin);
 const { userInfo } = userLogin;

 return (
  <Router>
   <div style={{ display: "flex" }}>
    <Sidebar />
    <div
     style={{
      display: "flex",
      marginLeft: userInfo ? "70px" : "-70px",
      minHeight: "80vh",
     }}
     className="container"
    >
     <Container className={userInfo ? "mt-3" : ""}>
      <Route path="/user/:userId/edit" component={UserEditView} />
      <Route path="/userList" component={UserListView} />
      <Route path="/profile" component={ProfileView} />
      <Route path="/analysis" component={AnalysisView} />
      <Route path="/addEmployee" component={AddEmployeeView} />
      <Route path="/employee/:id" component={EmployeeDetailsView} exact />
      <Route path="/login" component={LoginView} exact />
      <Route path="/register" component={RegisterView} exact />
      {/* <Route path="/page/:pageNumber" component={EmployeeListView} exact />*/}
      <Route path="/" component={DashboardView} exact />
      <Route path="/dashboard" component={DashboardView} exact />
      <Route path="/employeeList" component={EmployeeListView} exact />
      <Route path="/employeeList/:keyword" component={EmployeeListView} exact />
      <Route path="/settings" component={SettingsView} exact />
      {/* <Route path="/attendance/:id" component={AttendanceDetailsView} exact />*/}
      <Route path="/attendance" component={AttendanceView} exact />
      <Route path="/attendance/:keyword" component={AttendanceView} exact />
      <Route path="/forgot-password" component={ForgotPasswordView} exact />
      <Route path="/reset-password/:token" component={ResetPasswordView} />
      <Route path="/outletList" component={OutletListView} exact />
      <Route path="/addOutlet" component={AddOutletView} />
      {/* Route dengan token */}
     </Container>
    </div>
   </div>
   <Footer />
  </Router>
 );
};

export default App;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
 Form,
 Row,
 Col,
 FloatingLabel,
 Button,
 Container,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import FormContainer from "../../components/FormContainer";
import { login } from "../../actions/userActions";
import { LandingPageBgImage } from "../../components/BackgroundImage";
import { Logo } from "../../components/Logo";

const LoginView = ({ location, history }) => {
 const [email, setEmail] = useState("");
 const [password, setPassword] = useState("");

 const dispatch = useDispatch();

 const userLogin = useSelector((state) => state.userLogin);
 const { loading, error, userInfo } = userLogin;

 const redirect = location.search
  ? location.search.split("=")[1]
  : "/dashboard";

 useEffect(() => {
  if (userInfo) {
   history.push(redirect);
  }
 }, [history, userInfo, redirect]);

 const submitHandler = (e) => {
  e.preventDefault();
  dispatch(login(email, password));
 };

 return (
  <Container>
   {error && <Message variant="danger">{error}</Message>}
   {loading ? (
    <Loader />
   ) : (
    <>
     <LandingPageBgImage>
      <Container>
       <FormContainer>
        {/*eslint-disable-next-line*/}
        <h3></h3>
        <Logo />
        <Form onSubmit={submitHandler}>
         <FloatingLabel
          controlId="floatingInput"
          label="Email address"
          className="mb-3 mt-3"
         >
          <Form.Control
           type="email"
           placeholder="name@example.com"
           value={email}
           onChange={(e) => setEmail(e.target.value)}
           style={{ borderRadius: 5, width: "100%" }}
          />
         </FloatingLabel>
         <FloatingLabel
          controlId="floatingPassword"
          label="Password"
          className="mb-3"
         >
          <Form.Control
           type="password"
           placeholder="Password"
           value={password}
           onChange={(e) => setPassword(e.target.value)}
           style={{ borderRadius: 5 }}
          />
         </FloatingLabel>
         <Button
          type="submit"
          style={{ width: "100%" }}
          variant="danger"
          className="mb-2"
         >
          Sign In
         </Button>
         <Row className="mb-2">
          <Col
           style={{
            justifyContent: "center",
            display: "flex",
           }}
          >
           <Link to="/forgot-password">Forgot your password?</Link>
          </Col>
         </Row>
         <hr />
         <Link to={redirect ? `/register?redirect=${redirect}` : "/register"}>
          <Button
           type="button"
           style={{ width: "100%" }}
           variant="secondary"
           className="mb-4"
          >
           Register
          </Button>
         </Link>
        </Form>
       </FormContainer>
      </Container>
     </LandingPageBgImage>
    </>
   )}
  </Container>
 );
};

export default LoginView;
